import React from 'react';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

import logoMonkey from "./../assets/icons/logo_hoch-circle.svg";

class DatenschutzPage extends React.Component {
  render() {
    return (
      <div>
        <Navigation props={this.props} />
        <div className="uk-section uk-section-default uk-section-large">
          <div className="uk-container uk-container-large">
            <div className="uk-flex uk-flex-center">
              <img src={logoMonkey} height="200" width="200" alt="blindmonkey"/>
            </div>
            <h1 className="uk-text-center">Datenschutzerklärung</h1>
            <h2>1 Name und Anschrift des verantwortlichen Unternehmen</h2>
            <p>Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler
                Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist
                die:</p>
            <dl class="table w100">
                <dt>Unternehmen:</dt>
                <dd>Caarl GmbH</dd>
                <dt>Straße:</dt>
                <dd>Geleitstrasse 66</dd>
                <dt>PLZ / Ort:</dt>
                <dd>63456 Hanau</dd>
                <dt>Telefon:</dt>
                <dd>06181 9068918</dd>
                <dt>E-Mail:</dt>
                <dd>
                    <a href="mailto:mail@blindmonkey.de" title="E-Mail an: mail@blindmonkey.de">mail@blindmonkey.de</a>
                </dd>
                <dt>Website:</dt>
                <dd><a href="https://www.blindmonkey.de" target="_blank">www.blindmonkey.de</a></dd>
            </dl>
            <h2>2 Kontaktdaten des Datenschutzbeauftragten</h2>
            <p>Der Datenschutzbeauftragte des Verantwortlichen ist:</p>
            <dl class="table w200">
                <dt>Datenschutzbeauftragter:</dt>
                <dd>Herr Jürgen Rosenow</dd>
                <dt>Unternehmen:</dt>
                <dd>All-in-Media GmbH</dd>
                <dt>&nbsp;</dt>
                <dd>Gesellschaft für Datenschutz und Datensicherheit</dd>
                <dt>Straße:</dt>
                <dd>Markwaldstrasse 11</dd>
                <dt>PLZ / Ort:</dt>
                <dd>D-63073 Offenbach am Main</dd>
                <dt>Telefon</dt>
                <dd>+49 69 5699922-0</dd>
                <dt>Webseite</dt>
                <dd><a href="http://www.all-in-media.com" target="_blank">www.all-in-media.com</a></dd>
                <dt>E-Mail</dt>
                <dd><a href="mailto:datenschutz@caarl.de">datenschutz@caarl.de</a></dd>
            </dl>
            <h2>3 Rechtsgrundlage für die Verarbeitung personenbezogener Daten<br /></h2>
            <p>Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine <strong>Einwilligung</strong> der
                betroffenen Person einholen, dient <strong>Art. 6 Abs. 1 a</strong> EU-Datenschutzgrundverordnung
                (DSGVO) als Rechtsgrundlage.</p>
            <p>Bei der Verarbeitung von personenbezogenen Daten, die zur <strong>Erfüllung eines Vertrages</strong>,
                dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient <strong>Art. 6 Abs. 1
                    b</strong> DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur
                Durchführung vorvertraglicher Maßnahmen erforderlich sind.</p>
            <p>Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer <strong>rechtlichen
                Verpflichtung</strong> erforderlich ist, der unser Unternehmen unterliegt, dient <strong>Art. 6 Abs.
                1 c</strong> DSGVO als Rechtsgrundlage.</p>
            <p>Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines
                Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen
                das erstgenannte Interesse nicht, so dient <strong>Art. 6 Abs. 1 f</strong> DSGVO als
                Rechtsgrundlage für die Verarbeitung.</p>
            <h2>4 Datenlöschung und Speicherdauer</h2>
            <p>Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck
                der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den
                europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen
                Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde.</p>
            <p>Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen
                vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren
                Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.</p>
            <h2>5 Weitergabe an Dritte</h2>
            <p>Wir nutzen zur Verarbeitung die Unterstützung durch einen Auftragsverarbeitungsvertrag an uns
                gebundene Dienstleister wie z.B. den technischen Betreiber des Internetauftritts (Hoster).</p>
            <p>Es findet keine keine Übermittlung an weitere Dritte statt, soweit diese nicht durch einen
                Auftragsverarbeitungsvertrag an uns gebunden sind, eine gesetzliche Grundlage besteht oder explizit
                erwähnt werden.</p>
            <h2>6 Bereitstellung der Website und Erstellung von Logfiles</h2>
            <h3>6.1 Beschreibung und Umfang der Datenverarbeitung</h3>
            <p>Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und Informationen vom
                Computersystem des aufrufenden Rechners.</p>
            <p>Folgende Daten werden hierbei erhoben:</p>
            <ul>
                <li>Informationen über den Browsertyp und die verwendete Version</li>
                <li>Log-in-Zeit</li>
                <li>Aufgerufene und Verweildauer auf den Seiten</li>
                <li>Das Betriebssystem des Nutzers</li>
                <li>Den Internet-Service-Provider des Nutzers</li>
                <li>Die IP-Adresse des Nutzers</li>
                <li>Datum und Uhrzeit des Zugriffs</li>
                <li>Websites, von denen das System des Nutzers auf unsere Internetseite gelangt</li>
                <li>Websites, die vom System des Nutzers über unsere Website aufgerufen werden</li>
            </ul>
            <p>Die Logfiles enthalten IP-Adressen oder sonstige Daten, die eine gegebenenfalls eine Zuordnung zu
                einem Nutzer ermöglichen. Dies könnte beispielsweise der Fall sein, wenn der Link zur Website, von
                der der Nutzer auf die Internetseite gelangt, oder der Link zur Website, zu der der Nutzer wechselt,
                personenbezogene Daten enthält.</p>
            <p>Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert.</p>
            <p>Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers findet nicht
                statt.</p>
            <h3>6.2 Rechtsgrundlage für die Datenverarbeitung</h3>
            <p>Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist Art. 6 Abs. 1 f
                DSGVO.</p>
            <h3>6.3 Zweck der Datenverarbeitung</h3>
            <p>Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung
                der Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für
                die Dauer der Sitzung gespeichert bleiben.</p>
            <p>Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen.</p>
            <p>Zudem dienen uns die Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit unserer
                informationstechnischen Systeme.</p>
            <p>Eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt.</p>
            <p>In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1
                f DSGVO.</p>
            <h3>6.4 Dauer der Speicherung</h3>
            <p>Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr
                erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung der Website ist dies der
                Fall, wenn die jeweilige Sitzung beendet ist.</p>
            <p>Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens sieben Tagen der Fall. Eine
                darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer
                gelöscht oder verfremdet, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.<br />
            </p>
            <h3>6.5 Widerspruchs- und Beseitigungsmöglichkeit</h3>
            <p>Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles ist
                für den Betrieb der Internetseite zwingend erforderlich. Es besteht folglich seitens des Nutzers
                keine Widerspruchsmöglichkeit.</p>
            <h2>7 Verwendung von Cookies</h2>
            <h3>7.1 Beschreibung und Umfang der Datenverarbeitung</h3>
            <p>Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um Textdateien, die im Internetbrowser
                bzw. vom Internetbrowser auf dem Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine
                Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie
                enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim
                erneuten Aufrufen der Website ermöglicht. </p>
            <h4>7.1.1&nbsp;Technisch notwendige Cookies</h4>
            <p>Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige Elemente unserer
                Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert
                werden kann. </p>
            <p>In den Cookies werden dabei folgende Daten gespeichert und übermittelt:</p>
            <p>Es folgt eine Auflistung der gespeicherten Daten:</p>
            <ul>
                <li>Log-In-Informationen für Cookie-Zustimmung</li>
            </ul>
            <h4>7.1.2 Cookies zur Optimierung der Webseiten-Inhalte</h4>
            <p>Wir verwenden auf unserer Website darüber hinaus Cookies, die eine Analyse des Surfverhaltens der
                Nutzer ermöglichen. </p>
            <p>Auf diese Weise können folgende Daten übermittelt werden:</p>
            <ul>
                <li>Häufigkeit von Seitenaufrufen und Statistiken</li>
            </ul>
            <p>Die auf diese Weise erhobenen Daten der Nutzer werden durch technische Vorkehrungen pseudonymisiert.
                Daher ist eine Zuordnung der Daten zum aufrufenden Nutzer nicht mehr möglich. Die Daten werden nicht
                gemeinsam mit sonstigen personenbezogenen Daten der Nutzer gespeichert.</p>
            <p>Beim Aufruf unserer Website wird der Nutzer über die Verwendung von Cookies zu Analysezwecken
                informiert und seine Einwilligung zur Verarbeitung der in diesem Zusammenhang verwendeten
                personenbezogenen Daten eingeholt. In diesem Zusammenhang erfolgt auch ein Hinweis auf diese
                Datenschutzerklärung.</p>
            <h3>7.2 Rechtsgrundlage für die Datenverarbeitung </h3>
            <p>Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies ist
                Art. 6 Abs. 1 f DSGVO.</p>
            <p>Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies zu
                Analysezwecken ist bei Vorliegen einer diesbezüglichen Einwilligung des Nutzers Art. 6 Abs. 1 a
                DSGVO.</p>
            <h3>7.3 Zweck der Datenverarbeitung</h3>
            <p>Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die Nutzer
                zu vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht
                angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel
                wiedererkannt wird.</p>
            <p>Für folgende Anwendungen benötigen wir Cookies:</p>
            <ul>
                <li>Log-In-Informationen für Cookie-Zustimmung</li>
                <li>Häufigkeit von Seitenaufrufen und Statistiken</li>
            </ul>
            <p>Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von
                Nutzerprofilen verwendet.</p>
            <p>Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck, die Qualität unserer Website und ihre
                Inhalte zu verbessern. Durch die Analyse-Cookies erfahren wir, wie die Website genutzt wird und
                können so unser Angebot stetig optimieren.<br />Informationen zu den Analyse-Cookies finden Sie unten.
            </p>
            <p>In diesen Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der personenbezogenen
                Daten nach Art. 6 Abs. 1 f DSGVO.</p>
            <h3>7.4 Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit</h3>
            <p>Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite übermittelt.
                Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine
                Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies
                deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies
                kann auch automatisiert erfolgen. </p>
            <p>Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht mehr alle Funktionen der
                Website vollumfänglich genutzt werden.</p>
            <h2>8 E-Mail-Kontakt</h2>
            <h3>8.1&nbsp;Beschreibung und Umfang der Datenverarbeitung</h3>
            <p>Eine Kontaktaufnahme ist über die bereitgestellte E-Mail-Adresse möglich. In diesem Fall werden die
                mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert.</p>
            <p>Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die Daten werden
                ausschließlich für die Verarbeitung der Konversation verwendet.</p>
            <h3>8.2 Rechtsgrundlage für die Datenverarbeitung</h3>
            <p>Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des Nutzers Art.
                6 Abs. 1 a DSGVO.</p>
            <p>Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Übersendung einer E-Mail
                übermittelt werden, ist Art. 6 Abs. 1 f DSGVO.</p>
            <p>Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für
                die Verarbeitung Art. 6 Abs. 1 b DSGVO.</p>
            <h3>8.3 Zweck der Datenverarbeitung</h3>
            <p>Falle einer Kontaktaufnahme per E-Mail liegt hierin das erforderliche berechtigte Interesse an der
                Verarbeitung der Daten.</p>
            <h3>8.4 Dauer der Speicherung</h3>
            <p>Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr
                erforderlich sind. Für die personenbezogenen Daten die per E-Mail übersandt wurden, ist dies dann
                der Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist. Beendet ist die Konversation
                dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend
                geklärt ist.</p>
            <h3>8.5 Widerspruchs- und Beseitigungsmöglichkeit</h3>
            <p>Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung der personenbezogenen
                Daten zu widerrufen. Nimmt der Nutzer per E-Mail Kontakt mit uns auf, so kann er der Speicherung
                seiner personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall kann die Konversation
                nicht fortgeführt werden.</p>
            <p>Sie können einen Widerspruch oder der Widerruf der Speicherung jederzeit über E-Mail vornehmen.</p>
            <p>Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, werden in diesem
                Fall gelöscht.</p>
            <h2>9 Webanalyse durch Google Analytics</h2>
            <h3>9.1 Umfang der Verarbeitung personenbezogener Daten</h3>
            <p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. ("Google"). Google
                Analytics verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert werden und die
                eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten
                Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in
                den USA übertragen und dort gespeichert.</p>
            <ul>
                <li>Zwei Bytes der IP-Adresse des aufrufenden Systems des Nutzers</li>
                <li>Die aufgerufene Webseite</li>
                <li>Die Website, von der der Nutzer auf die aufgerufene Webseite gelangt ist (Referrer)</li>
                <li>Die Unterseiten, die von der aufgerufenen Webseite aus aufgerufen werden</li>
                <li>Die Verweildauer auf der Webseite</li>
                <li>Die Häufigkeit des Aufrufs der Webseite</li>
            </ul>
            <p>Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite, wird Ihre IP-Adresse von Google
                jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
                Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
                IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des
                Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website
                auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der
                Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu
                erbringen.</p>
            <p>Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen
                Daten von Google zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende
                Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem
                Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
                Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
                Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
                Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen
                und installieren: <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage...</a>
            </p>
            <p>Alternativ zum Browser-Plugin oder innerhalb von Browsern auf mobilen Geräten klicken Sie bitte auf
                den folgenden Link, um ein Opt-Out-Cookie zu setzen, der die Erfassung durch Google Analytics
                innerhalb dieser Website zukünftig verhindert (dieses Opt-Out-Cookie funktioniert nur in diesem
                Browser und nur für diese Domain, löschen Sie Ihre Cookies in diesem Browser, müssen Sie diesen Link
                erneut klicken):</p>
            <p><strong><a href="javascript:gaOptout()">Google Analytics deaktivieren</a></strong></p>
            <p>Die Google Analytics ist mit der Erweiterung "_anonymizeIp()" so eingestellt, dass die IP-Adressen
                nicht vollständig gespeichert werden, sondern 2 Bytes der IP-Adresse maskiert werden (Bsp.:
                192.168.xxx.xxx).<br /></p>
            <p>Auf diese Weise ist eine Zuordnung der gekürzten IP-Adresse zum aufrufenden Rechner nicht mehr
                möglich.</p>
            <h3>9.2 Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h3>
            <p>Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer ist Art. 6 Abs. 1 lit. f
                DSGVO.</p>
            <h3>9.3 Zweck der Datenverarbeitung</h3>
            <p>Die Verarbeitung der personenbezogenen Daten der Nutzer ermöglicht uns eine Analyse des
                Surfverhaltens unserer Nutzer. Wir sind in durch die Auswertung der gewonnen Daten in der Lage,
                Informationen über die Nutzung der einzelnen Komponenten unserer Webseite zusammenzustellen.</p>
            <p>Dies hilft uns dabei unsere Webseite und deren Nutzerfreundlichkeit stetig zu verbessern. In diesen
                Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der Daten nach Art. 6 Abs. 1 f
                DSGVO. Durch die Anonymisierung der IP-Adresse wird dem Interesse der Nutzer an deren Schutz
                personenbezogener Daten hinreichend Rechnung getragen.</p>
            <h3>9.4&nbsp;Dauer der Speicherung</h3>
            <p>Die Daten werden gelöscht, sobald sie für unsere Aufzeichnungszwecke nicht mehr benötigt werden.<br />
            </p>
            <p>In unserem Fall ist dies nach 14 Monaten&nbsp;der Fall.</p>
            <h3>9.5 Widerspruchs- und Beseitigungsmöglichkeit</h3>
            <p>Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite übermittelt.
                Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine
                Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies
                deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies
                kann auch automatisiert erfolgen. Werden Cookies für unsere Website deaktiviert, können
                möglicherweise nicht mehr alle Funktionen der Website vollumfänglich genutzt werden.</p>
            <p>Wir bieten unseren Nutzern auf unserer Website die Möglichkeit eines Opt-Out aus dem
                Analyseverfahren. Hierzu müssen Sie dem entsprechenden Link folgen. Auf diese Weise wird ein
                weiterer Cookie auf ihrem System gesetzt, der unserem System signalisiert die Daten des Nutzers
                nicht zu speichern. Löscht der Nutzer den entsprechenden Cookie zwischenzeitlich vom eigenen System,
                so muss er den Opt-Out-Cookie erneut setzten.<br /></p>
            <p>Nähere Informationen zu den Privatsphäreeinstellungen der Matomo Software finden Sie unter folgendem
                Link: <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage...</a>
            </p>
            <h2>10 Facebook Pixel</h2>
              <p>Unsere Website nutzt zur Konversionsmessung das Besucheraktions-Pixel von Facebook, Facebook Inc., 1601
                  S. California Ave, Palo Alto, CA 94304, USA (“Facebook”).</p> <p>So kann das Verhalten der
              Seitenbesucher nachverfolgt werden, nachdem diese durch Klick auf eine Facebook-Werbeanzeige auf die
              Website des Anbieters weitergeleitet wurden. Dadurch können die Wirksamkeit der Facebook-Werbeanzeigen für
              statistische und Marktforschungszwecke ausgewertet werden und zukünftige Werbemaßnahmen optimiert
              werden.</p> <p>Die erhobenen Daten sind für uns als Betreiber dieser Website anonym, wir können keine
              Rückschlüsse auf die Identität der Nutzer ziehen. Die Daten werden aber von Facebook gespeichert und
              verarbeitet, sodass eine Verbindung zum jeweiligen Nutzerprofil möglich ist und Facebook die Daten für
              eigene Werbezwecke, entsprechend der <a href="https://de-de.facebook.com/about/privacy/" target="_blank"
                                                      rel="noopener">Facebook-Datenverwendungsrichtlinie</a> verwenden
              kann. Dadurch kann Facebook das Schalten von Werbeanzeigen auf Seiten von Facebook sowie außerhalb von
              Facebook ermöglichen. Diese Verwendung der Daten kann von uns als Seitenbetreiber nicht beeinflusst
              werden.</p> <p>Die Nutzung von Facebook-Pixel erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
              Websitebetreiber hat ein berechtigtes Interesse an effektiven Werbemaßnahmen unter Einschluss der sozialen
              Medien.</p> <p>In den Datenschutzhinweisen von Facebook finden Sie weitere Hinweise zum Schutz Ihrer
              Privatsphäre: <a href="https://de-de.facebook.com/about/privacy/" target="_blank"
                               rel="noopener">https://de-de.facebook.com/about/privacy/</a>.</p> <p>Sie können außerdem
              die Remarketing-Funktion “Custom Audiences” im Bereich Einstellungen für Werbeanzeigen unter <a
                  href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen" target="_blank"
                  rel="noopener">https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</a> deaktivieren.
              Dazu müssen Sie bei Facebook angemeldet sein.</p> <p>Wenn Sie kein Facebook Konto besitzen, können Sie
              nutzungsbasierte Werbung von Facebook auf der Website der European Interactive Digital Advertising
              Alliance deaktivieren: <a href="http://www.youronlinechoices.com/de/praferenzmanagement/" target="_blank"
                                        rel="noopener">http://www.youronlinechoices.com/de/praferenzmanagement/</a>.</p>
            <h2>10&nbsp;Rechte der betroffenen Person</h2>
            <p>Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen
                Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:</p>
            <h3>10.1&nbsp;Auskunftsrecht</h3>
            <p>Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten,
                die Sie betreffen, von uns verarbeitet werden.</p>
            <p>Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen
                Auskunft verlangen:</p>
            <ul>
                <li>die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</li>
                <li>die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</li>
                <li>die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden
                    personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;
                </li>
                <li>die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls
                    konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;
                </li>
                <li>das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen
                    Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines
                    Widerspruchsrechts gegen diese Verarbeitung;
                </li>
                <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
                <li>alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten
                    nicht bei der betroffenen Person erhoben werden;
                </li>
            </ul>
            <p>Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen
                Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem
                Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang
                mit der Übermittlung unterrichtet zu werden.</p>
            <h3>10.2 Recht auf Berichtigung</h3>
            <p>Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen,
                sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig
                sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.</p>
            <h3>10.3 Recht auf Einschränkung der Verarbeitung</h3>
            <p>Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie
                betreffenden personenbezogenen Daten verlangen:</p>
            <ul>
                <li>wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die
                    es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;
                </li>
                <li>die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und
                    stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;
                </li>
                <li>der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger
                    benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                    benötigen, oder
                </li>
                <li>wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch
                    nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen
                    überwiegen.
                </li>
            </ul>
            <p>Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten
                – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung
                oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
                juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines
                Mitgliedstaats verarbeitet werden.</p>
            <p>Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von
                dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.</p>
            <h3>10.4 Recht auf Löschung</h3>
            <h4>10.4.1 Löschungspflicht</h4>
            <p>Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten
                unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu
                löschen, sofern einer der folgenden Gründe zutrifft:</p>
            <ul>
                <li>Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf
                    sonstige Weise verarbeitet wurden, nicht mehr notwendig.
                </li>
                <li>Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 a oder Art. 9
                    Abs. 2 a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die
                    Verarbeitung.
                </li>
                <li>Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine
                    vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2
                    DSGVO Widerspruch gegen die Verarbeitung ein.
                </li>
                <li>Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
                <li>Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen
                    Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der
                    Verantwortliche unterliegt.
                </li>
                <li>Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
                    Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
                </li>
            </ul>
            <h4>10.4.2 Löschung von Informationen bei Dritten</h4>
            <p>Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er
                gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der
                verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art,
                um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber
                zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen
                personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt
                haben.</p>
            <h4>10.4.3 Ausnahmen bei Löschungen</h4>
            <p>Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist</p>
            <ul>
                <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
                <li>zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union
                    oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung
                    einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt,
                    die dem Verantwortlichen übertragen wurde;
                </li>
                <li>aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs.
                    2 h und i sowie Art. 9 Abs. 3 DSGVO;
                </li>
                <li>für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische
                    Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter
                    Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung
                    unmöglich macht oder ernsthaft beeinträchtigt, oder
                </li>
                <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>
            </ul>
            <h3>10.5 Recht auf Unterrichtung</h3>
            <p>Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem
                Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie
                betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten
                oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder
                ist mit einem unverhältnismäßigen Aufwand verbunden.</p>
            <p>Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu
                werden.</p>
            <h3>10.6 Recht auf Datenübertragbarkeit</h3>
            <p>Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen
                bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
                Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den
                Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern</p>
            <ul>
                <li>die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 a DSGVO oder Art. 9 Abs. 2 a DSGVO
                    oder auf einem Vertrag gem. Art. 6 Abs. 1 b DSGVO beruht und
                </li>
                <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
            </ul>
            <p>In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden
                personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt
                werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch
                nicht beeinträchtigt werden.</p>
            <p>Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für
                die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
                öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.</p>
            <h3>10.7 Widerspruchsrecht</h3>
            <p>Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen
                die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 e oder
                f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes
                Profiling.</p>
            <p>Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn,
                er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte
                und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder
                Verteidigung von Rechtsansprüchen.</p>
            <p>Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben
                Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen
                Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit
                solcher Direktwerbung in Verbindung steht.</p>
            <p>Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden
                personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.</p>
            <p>Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft
                – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren
                auszuüben, bei denen technische Spezifikationen verwendet werden.</p>
            <h3>10.8 Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h3>
            <p>Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch
                den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
                erfolgten Verarbeitung nicht berührt.</p>
            <h3>10.9 Automatisierte Entscheidung im Einzelfall einschließlich Profiling</h3>
            <p>Es findet grundsätzlich keine automatisierte Entscheidungsfindung im Sinne des Art. 22 DSGVO
                statt.</p>
            <h3>10.10 Recht auf Beschwerde bei einer Aufsichtsbehörde</h3>
            <p>Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen
                das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
                Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der
                Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO
                verstößt.</p>
            <p>Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer
                über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen
                Rechtsbehelfs nach Art. 78 DSGVO.</p>
            <p>Stand: 17.05.2018</p>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
};

export default DatenschutzPage
